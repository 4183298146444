import "./App.css";
import AppComponent from "./components/AppComponent";

function App() {
  console.log("app");

  return <AppComponent />;
}

export default App;
